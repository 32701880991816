/**
 * Google Analytics
 */

export const load = function load(url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    if (typeof document === 'undefined') {
      return;
    }
    const head = document.head || document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.async = true;
    script.src = url;
    head.appendChild(script);

    script.onload = resolve;
    script.onerror = reject;
  });
};

if (window.AppConfig.gaId) {
  load(`https://www.googletagmanager.com/gtag/js?id=${window.AppConfig.gaId}&l=gtagDataLayer`)
    .then(() => {
      window.gtagDataLayer = window.gtagDataLayer || [];
      window.gtag = function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.gtagDataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', window.AppConfig.gaId);
    })
    .catch((error) => {
      console.error('Google Analytics 初始化失败', error);
      return error;
    });
}
